import { graphql } from "gatsby"
import Img from "gatsby-image"
import React, { useState } from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import Button from "../components/Button"
import Newsletter from "../components/Newsletter"

const ContactPage = ({ data }) => {

  const [formState, setFormState] = useState({
    name: "",
    lastname: "",
    email: "",
    message: ""
  })

  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    })
  }
const handleSubmit = e => {
  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: encode({ "form-name": "contact", ...formState })
  })
    .then(() => alert("Se ha enviado tu mensaje"))
    .catch(error => alert(error));

  e.preventDefault();
}
  return (
    <Layout>
    <SiteMetadata title="Contacto" description="¡Trabajemos juntos! Recorreré este camino contigo, vamos a comenzar ese bienestar que quieres ver y sentir. Déjame un mensaje en hola@luzamira.com" />

    <div className="bg-white">
    <div className="container pt-6 lg:pt-16">
      <h2 className="text-xl mb-4 leading-tight font-bold text-center text-gray-900 sm:mb-3 sm:text-2xl md:text-4xl lg:text-6xl">Contacto</h2>
      <p className="text-base mb-4 leading-normal font-light text-center text-gray-900 sm:mb-6 sm:text-lg md:text-xl lg:text-2xl">¿Listo para dar el primer paso?</p>
    </div>
  </div>

    <div className="bg-white">
      <div className="container pb-12 lg:pb-16">
        <div className="flex flex-wrap justify-between items-center">
          
          <div className="w-full pr-0 md:w-1/2 xl:w-1/2 md:pl-12 md:pr-8">
            <Img
              fluid={data.LuzAmiraContacto.childImageSharp.fluid}
              alt="Luz Amira contacto"
              className="rounded-md shadow-md"
            />
          </div>
          <div className=" w-full pr-0 md:w-1/2 xl:w-1/2 pb-8 md:pb-0 md:pl-8">
            <div className="mt-4 leading-loose font-book">
              <p>¡Trabajemos juntos!</p>
              <p>Recorreré este camino contigo, vamos a comenzar ese bienestar que quieres ver y sentir.
                Puedes escribirme un mensaje en el formulario o enviarme un correo a &nbsp;
                <a
                  href="mailto:hola@luzamira.com"
                  className="border-b border-gray-500 hover:border-green-600 hover:text-green-600"
                  >
                  hola@luzamira.com
                </a>
              </p>
              <br/>
              <form 
              onSubmit={handleSubmit} 
              name="contact" 
              method="post" 
              data-netlify="true" 
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact" />

              <div hidden> 
                <input name="bot-field" />
              </div> 
              
                <div className="pb-5">
                <label className="font-medium" htmlFor="name">Nombre <br /></label>
                  <input 
                    className="appearance-none w-full px-5 py-3 border border-green-600 leading-snug rounded-md text-gray-900 bg-white placeholder-gray-600 focus:outline-none focus:shadow-outline focus:border-green-600 transition duration-150 ease-in-out"
                    id="name"
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={formState.name}
                    placeholder="Tu nombre"
                    required
                  />
                </div>

                <div className="pb-5">
                  <label className="font-medium" htmlFor="lastname">Apellido <br /></label>
                  <input
                    className="appearance-none w-full px-5 py-3 border border-green-600 leading-snug rounded-md text-gray-900 bg-white placeholder-gray-600 focus:outline-none focus:shadow-outline focus:border-green-600 transition duration-150 ease-in-out"
                    id="lastname"
                    type="text"
                    name="lastname"
                    onChange={handleChange}
                    value={formState.lastname}
                    placeholder="Tu apellido"
                    required
                  />
                </div>

                <div className="pb-5">
                  <label className="font-medium" htmlFor="email">Correo <br /></label>
                  <input
                    className="appearance-none w-full px-5 py-3 border border-green-600 leading-snug rounded-md text-gray-900 bg-white placeholder-gray-600 focus:outline-none focus:shadow-outline focus:border-green-600 transition duration-150 ease-in-out"
                    id="email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={formState.email}
                    placeholder="Tu correo"
                    required
                  />
                </div>

                <div className="pb-5">
                  <label className="font-medium" htmlFor="message">Mensaje <br /></label>
                  <textarea
                    className="appearance-none w-full px-5 py-3 border border-green-600 leading-snug rounded-md text-gray-900 bg-white placeholder-gray-600 focus:outline-none focus:shadow-outline focus:border-green-600 transition duration-150 ease-in-out"
                    id="message"
                    type="message"
                    name="message"
                    onChange={handleChange}
                    value={formState.message}
                    placeholder="Tu mensaje"
                    required
                  />
                </div>
                <div>
                  <Button type="submit">Enviar</Button>
                </div>
            </form>
            </div>
          </div>


        </div>
      </div>
    </div>
    <Newsletter />
  </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query {
    LuzAmiraContacto: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600,, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }  
  }
`